<template>
  <div class="overflow-auto" style="z-index:400">
    <form class="form-custom">
      <div class="form-row justify-content-md-start pt-3 clm-cari">
        <div class="col-md-9 mb-3 txt-cari">
          <input
            type="text"
            class="form-control border-0 msk-cari"
            id="inputEmail"
            autocomplete="autocomplete_off_randString"
            v-model="search"
            :placeholder="functionData"
          />
        </div>
        <div class="col-md-3 mb-3 btn-cari">
          <button type="submit" class="tmbl-cari" @click.prevent="initData()">
            <span v-if="!loading"><i class="flaticon-search pr-3"></i>Cari</span>
            <b-spinner medium v-else ></b-spinner>
          </button>
        </div>
      </div>
    </form>
    <div style="display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 2vw;
    gap: 2vw;">
      <div v-if="perkumpulan || yayasan" style="border-radius: 0.5vw; border: 1px solid rgba(84, 120, 152, 0.38); color: rgba(5, 58, 105, 0.38); padding: 0.5vw;" v-on:click="ormasTerdaftar()">
        Ormas Tidak Badan Hukum Terdaftar
      </div>
      <div v-if="terdaftar" style="border-radius: 0.5vw; background: #053a69; color: white; padding: 0.5vw;" v-on:click="ormasTerdaftar()">
        Ormas Tidak Badan Hukum Terdaftar
      </div>
      <div v-if="terdaftar || yayasan" style="border-radius: 0.5vw; border: 1px solid rgba(84, 120, 152, 0.38); color: rgba(5, 58, 105, 0.38); padding: 0.5vw;" v-on:click="ormasPerkumpulan()">
        Ormas Badan Hukum Perkumpulan
      </div>
      <div v-if="perkumpulan" style="border-radius: 0.5vw; background: #053a69; color: white; padding: 0.5vw;" v-on:click="ormasPerkumpulan()">
        Ormas Badan Hukum Perkumpulan
      </div>
      <div v-if="terdaftar || perkumpulan" style="border-radius: 0.5vw; border: 1px solid rgba(84, 120, 152, 0.38); color: rgba(5, 58, 105, 0.38); padding: 0.5vw;" v-on:click="ormasYayasan()">
        Ormas Badan Hukum Yayasan
      </div>
      <div v-if="yayasan" style="border-radius: 0.5vw; background: #053a69; color: white; padding: 0.5vw;" v-on:click="ormasYayasan()">
        Ormas Badan Hukum Yayasan
      </div>
    </div>
    <div
      class="float-right"
      style="margin-top: -90px; color: rgba(0, 0, 0, 0.6);"
    >
      <b-button variant="link" @click="$router.push('/')">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9844 1.42188L8.40625 7L13.9844 12.5781L12.5781 13.9844L7 8.40625L1.42188 13.9844L0.015625 12.5781L5.59375 7L0.015625 1.42188L1.42188 0.015625L7 5.59375L12.5781 0.015625L13.9844 1.42188Z"
            fill="#4D4D4D"
          />
        </svg>
      </b-button>
    </div>
    <!-- <div class="float-right">
      <p style="margin-top: -90px; color: rgba(0, 0, 0, 0.6);">
        {{ items.length }} Hasil yang ditampilkan
      </p>
    </div> -->
    <!-- <b-button @click="toggleBusy">Toggle Busy State</b-button> -->
    <div v-if="terdaftar">
      <div class="tabel-css">
        <b-table
          show-empty
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="0"
          text-left
          class="table-custom"
          thead-class="thead-block"
          table-class="table-content"
          :tbody-tr-class="rowVariantHandler"
        >
          <template #cell(actions)="row">
            <button
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1 btn-transparent"
            >
              Detail
            </button>
          </template>
          <template #empty="">
              <div v-if="!loading" style="text-align: center;">
              
            Tidak ada data</div>
            <div v-if="loading" style="text-align: center;">
              
              Sedang memuat....</div>
          </template>
          <!-- <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template> -->
          <!--      <template #cell(actions)="row">
            <router-link :to="{ name: 'detail', params: { row } }">
              <button size="sm" class="mr-1 btn-transparent">
                Detail
              </button>
            </router-link>
          </template>-->
        </b-table>
      </div>
      <b-pagination
        size="md"
        :total-rows="itemCount"
        align="center"
        v-model="currentPage"
        :per-page="10"
        next-text="Selanjutnya >"
        prev-text="< Sebelumnya"
        first-number
        last-number
        pills
      ></b-pagination>
    </div>
    <div v-if="perkumpulan">
      <div class="tabel-css">
        <b-table
          show-empty
          :items="itemsPerkumpulan"
          :fields="fieldsPerkumpulan"
          :current-page="currentPagePerkumpulan"
          :per-page="0"
          text-left
          class="table-custom"
          thead-class="thead-block"
          table-class="table-content"
        >
          <template #cell(actions)="row">
              <a
                size="sm"
                class="mr-1 btn-transparent"
                href="https://ahu.go.id/pencarian/profil-perkumpulan"
              >
                Detail
              </a>
            </template>
            <template #empty="">
              <div v-if="!loadingPerkumpulan" style="text-align: center;">
              
            Tidak ada data</div>
            <div v-if="loadingPerkumpulan" style="text-align: center;">
              
              Sedang memuat....</div>
          </template>
        </b-table>
      </div>
      <b-pagination
        size="md"
        :total-rows="itemCountPerkumpulan"
        align="center"
        v-model="currentPagePerkumpulan"
        :per-page="10"
        next-text="Selanjutnya >"
        prev-text="< Sebelumnya"
        first-number
        last-number
        pills
      ></b-pagination>
    </div>
    <div v-if="yayasan">
      <div class="tabel-css">
        <b-table
          show-empty
          :items="itemsYayasan"
          :fields="fieldsYayasan"
          :current-page="currentPageYayasan"
          :per-page="0"
          text-left
          class="table-custom"
          thead-class="thead-block"
          table-class="table-content"
        >
        <template #cell(actions)="row">
              <a
                size="sm"
                class="mr-1 btn-transparent"
                href="https://ahu.go.id/pencarian/profil-yayasan"
              >
                Detail
              </a>
            </template>
            <template #empty="">
              <div v-if="!loadingYayasan" style="text-align: center;">
              
            Tidak ada data</div>
            <div v-if="loadingYayasan" style="text-align: center;">
              
              Sedang memuat....</div>
          </template>
      </b-table>
      </div>
      <b-pagination
        size="md"
        :total-rows="itemCountYayasan"
        align="center"
        v-model="currentPageYayasan"
        :per-page="10"
        next-text="Selanjutnya >"
        prev-text="< Sebelumnya"
        first-number
        last-number
        pills
      ></b-pagination>
    </div>

    <!-- Info Modal Detail -->
    <b-modal
      :id="infoModal.id"
      ok-only
      centered
      size="xl"
      hide-backdrop
      content-class="shadow"
      @hide="resetInfoModal"
      hide-header
      hide-footer
    >
      <div style="padding: 32px">
        <b-form-row>
          <b-col>
            <b-row>
              <img
                src="assets/images/logo-lingkungan-hidup.png"
                style="width: 40px; height: 40px; margin-bottom: 20px; margin-left: 8px"
                alt="abstract"
              />
              <h5
                style="font-weight: bold; font-size: 16px; line-height: 38px; margin-left: 8px"
              >
                {{ infoModal.title }}
              </h5>
              <span
                class="icon-flag"
                :style="{ backgroundImage: `url(${flagImage})` }"
              >
              </span>
            </b-row>
            <b-row v-for="(item, i) in profil" :key="i">
              <b-col sm="4"
                ><p style="font-weight: 600">{{ item.label }}</p></b-col
              >:<b-col sm="6">{{ item.answer }}</b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-table-simple responsive style="margin-top: 104px">
              <b-thead class="table-head">
                <b-tr>
                  <b-th>Nama</b-th>
                  <b-th>Jabatan</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class="table-content">
                <b-tr>
                  <b-td>Anugrah Kurniawan</b-td>
                  <b-td>Ketua</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Acep Sabar, SH</b-td>
                  <b-td>Sekretaris</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Agung Budi Prasetyo</b-td>
                  <b-td>Bendahara</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-form-row>
        <div class="text-center">
          <button
            class="mt-16 text-center button-back"
            @click="$bvModal.hide('info-modal')"
          >
            Kembali
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isValidDate, leapingDate } from '../../../../myUtils';
import axios from "axios";

const fiveYearsAgo = leapingDate('-5 years')
// Get expired yesterday
fiveYearsAgo.setDate(fiveYearsAgo.getDate() - 1);

export default {
  name: "ResultFloat",
  data() {
    return {
      baseURL_bakkum: process.env.VUE_APP_APPLICATION_BAKUM_URL,
      // isBusy: false,
      lebar: document.body.clientWidth,
      terdaftar: true,
      perkumpulan: false,
      yayasan: false,
      perPage: 10,
      perPagePerkumpulan: 20,
      perPageYayasan: 20,
      currentPage: 1,
      currentPagePerkumpulan: 1,
      currentPageYayasan: 1,
      currentOffset: 0,
      totalItems: 0,
      items: [],
      itemsPerkumpulan: [],
      itemsYayasan: [],
      loading: false,
      loadingPerkumpulan: false,
      loadingYayasan: false,
      offsetData: 0,
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      profil: [
        {
          label: "Nama Ormas",
          answer: "Nama"
        },
        {
          label: "Singkatan",
          answer: "OT1"
        },
        {
          label: "Nomor SKT Aktif",
          answer: "01-00-00/109/D.IV.1/XI"
        },
        {
          label: "Status Sekretariat",
          answer: "Jl Alamat"
        },
        {
          label: "Alamat sekretariat",
          answer: "Jl Alamat"
        },
        {
          label: "Kabupaten/Kota",
          answer: "Jakarta Timur"
        },
        {
          label: "Provinsi",
          answer: "DKI Jakarta"
        },
        {
          label: "Nomor Telepon",
          answer: "021 - XXXXXXX"
        },
        {
          label: "Nomor FAX",
          answer: "021 - XXXXXXX"
        },
        {
          label: "Email",
          answer: "Email@Domain.com"
        },
        {
          label: "NPWP",
          answer: "XX.XXX.XXX.X-XXX.XXX"
        },
        {
          label: "Ciri khusus",
          answer: "kesamaan kegiatan"
        },
        {
          label: "Bidang Kegiatan",
          answer: "Lingkungan hidup"
        },
        {
          label: "Wadah Berhimpun",
          answer: "Belum ada"
        },
        {
          label: "Badan Otonom",
          answer: "Belum ada"
        },
        {
          label: "Sayap Organisasi",
          answer: "Belum ada"
        }
      ],
      fields: [
        {
          key: "nama_ormas",
          label: "Nama Ormas"
        },
        {
          key: "masterLokasiSekretariat.nama_lokasi",
          label: "Lokasi"
        },
        {
          key: "bidangkegiatans",
          label: "Bidang Kegiatan"
          // formatter: (value, key, item) => item
        },
        {
          key: "no_telp",
          label: "Telepon/Fax",
          formatter: (value, key, item) => (item === "null" ? item : "-")
        },
        {
          key: "email",
          label: "Email"
        },
        {
          key: "actions",
          label: "Aksi"
        }
      ],
      fieldsPerkumpulan: [
        {
          key: "nama",
          label: "Nama Ormas"
        },
        {
          key: "nama_kedudukan_provinsi",
          label: "Provinsi"
        },
        {
          key: "nama_kedudukan_kabupaten",
          label: "Kabupaten/Kota"
          // formatter: (value, key, item) => item
        },
        {
          key: "actions",
          label: "Aksi"
        }
      ],
      fieldsYayasan: [
        {
          key: "nama",
          label: "Nama Ormas"
        },
        {
          key: "nama_kedudukan_provinsi",
          label: "Provinsi"
        },
        {
          key: "nama_kedudukan_kabupaten",
          label: "Kabupaten/Kota"
          // formatter: (value, key, item) => item
        },
        {
          key: "actions",
          label: "Aksi"
        }
      ],
      search: "",
      oldSearch: "",
      itemCount: 0,
      itemCountPerkumpulan: 0,
      itemCountYayasan: 0,
      typing: false
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    functionData() {
      return this.$route.params.keyword;
    },
    flagImage() {
      return process.env.BASE_URL + "media/svg/flags/226-united-states.svg";
    }
  },
  mounted() {
    this.fetchData();
    this.itemCountGet();
    // this.ormasPerkumpulan();
    // this.ormasYayasan();
      this.GetPerkumpulan();
      this.GetYayasan();
  },
  beforeMount(){
    this.search = this.$route.params.keyword
    this.oldSearch = this.search
  },
  methods: {
    ormasTerdaftar() {
      this.terdaftar = true;
      this.perkumpulan = false;
      this.yayasan = false;
    },
    ormasPerkumpulan() {
      this.terdaftar = false;
      this.perkumpulan = true;
      this.yayasan = false;
      // this.GetPerkumpulan();
    },
    ormasYayasan() {
      this.terdaftar = false;
      this.perkumpulan = false;
      this.yayasan = true;
      // this.GetYayasan();
    },
    GetPerkumpulan(){
      this.loadingPerkumpulan = true
      this.itemsPerkumpulan = []
      axios
        .get(this.baseURL_bakkum + 'cariPerkumpulan?nama=' + this.search + '&page=' + this.currentPagePerkumpulan)
        .then((itemsPerkumpulans) => {
          var l = 0;
          for(l=0; l<itemsPerkumpulans.data.data.length; l++){
              this.itemsPerkumpulan.push(itemsPerkumpulans.data.data[l]);
          }
        this.itemCountPerkumpulan = (itemsPerkumpulans.data.totalCount)
        this.loadingPerkumpulan = false
      })
    },
    GetYayasan(){
      this.loadingYayasan = true
      this.itemsYayasan = []
      axios
        .get(this.baseURL_bakkum + 'cariYayasan?nama=' + this.search + '&page=' + this.currentPageYayasan)
        // .then((itemsYayasans) => {
        //       var l = 0;
        //       for(l=0; l<itemsYayasans.data.data.length; l++){
        //         this.itemsYayasan.push(itemsYayasans.data.data[l]);
        //       }
        //       this.totalRowsBadanHukum = itemsYayasans.data.meta.total
        //       this.loadingBadanHukum = false
        //     })
        .then((itemsYayasans) => {
          var l = 0;
          for(l=0; l<itemsYayasans.data.data.length; l++){
              this.itemsYayasan.push(itemsYayasans.data.data[l]);
          }
        this.itemCountYayasan = (itemsYayasans.data.totalCount)
        this.loadingYayasan = false
      })
    },
    rowVariantHandler(item) {
      const tanggalSkt = item ? item.tanggal_skt : ''

      if (isValidDate(tanggalSkt)) {
        const dataDate = new Date(tanggalSkt)

        if (dataDate.getTime() < fiveYearsAgo.getTime()) {
          return `text-danger`
        }
      }
    },
    initData(){
      if (this.search.length >= 3 || this.search.length == 0) {
        this.fetchData();
        this.itemCountGet()
        this.GetPerkumpulan()
        this.GetYayasan()

        if (this.search.length > 0) {
          this.$router.replace({ path: '/result/' + this.search })
        } else {
          this.$router.replace({ path: '/result/' + this.oldSearch })
        }
      }
    },
    async itemCountGet() {
      this.itemCount = await fetch(process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pencarian-data-ormas/count?where=" + 
       encodeURIComponent(JSON.stringify({nama_ormas: { ilike:`%${this.search.trim()}%`}})))
        .then((res) => {
          return res.json();
        })
        .then(items => items.count)
    },
    async fetchData() {
      this.loading = true
      // this.isBusy(true);
      let getFilter = {
        offset: this.currentOffset * 10, 
        limit: 10,
        skip: 0,
        order: "id",
        where: {
          nama_ormas: { ilike: `%${this.search.trim()}%` }
        },
        include: [
          {
            relation: "bidangKegiatans",
            scope: {
              include: [{ relation: "masterJenisOrmas" }]
            }
          },
          { relation: "masterLokasiSekretariat" },
        ]
      };
      this.items = await fetch(
        process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pencarian-data-ormas?filter=" +
          encodeURIComponent(JSON.stringify(getFilter))
      )
        .then(res => {
          this.loading = false
          this.totalItems = parseInt(res.headers.get("x-total-count"), 10);
          return res.json();
        })
        .then(items => items)
        .catch(error => {
          this.loading = false
          this.errorMessage = error;
        });
    },
    info(item) {
      this.infoModal.title = `${item.nama_ormas}`;
      localStorage.setItem('dataDetail', JSON.stringify(item))
      // this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      this.$router.push('/detail?uid=' + item.uid)
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    }
  },
  watch: {
    currentPage(to){
      this.currentOffset = to - 1
      this.fetchData()
    },
    currentPagePerkumpulan(to){
      this.currentPagePerkumpulan = to
      this.GetPerkumpulan()
    },
    currentPageYayasan(to){
      this.currentPageYayasan = to
      this.GetYayasan()
    },
    
    search: {
      handler() {
        // Check if the user has stopped typing
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        this.typingTimeout = setTimeout(() => {
          // User has stopped typing, perform your action here
          this.initData();
        }, 500); // Adjust the delay as needed (1 second in this example)
      },
      immediate: false, // Set to true if you want to trigger it immediately
    }
  }
};
</script>

<style scope>
table {
  border:none !important;
}
.thead-block {
  text-align: center;
  background: #053a69;
  color: #ffffff;
  border:none;
}
.thead-block tr th:first-child {
        border-top-left-radius: 15px
      
}
.thead-block tr th:last-child {
        border-top-right-radius: 15px
}
.thead-block tr th aria-colindex:5 {
  :disabled

}
  
.table-content {
  background: #fafcff;
  text-align: left;
  border-radius: 0 0 15px 15px;
}
.table-custom {
  border-radius: 15px 15px 15px 15px !important;
}
.form-custom {
  width: 45%;
  height: auto;
  /* left: 102px; */
  /* top: 232px; */
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 15px;
  margin-bottom: 40px;
  /* padding-left: 15px; */
  background: #ffffff;
  display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.clm-cari{
  width: 100%;
    height: auto;
        padding-top: 1%!important;
    padding-bottom: 1% !important;
}
.txt-cari{
  display: flex;
  align-content: center;
    align-items: center;
  margin-left: 3.5%;
  max-width: 75%;
margin-bottom: unset !important;
padding-right: unset !important;
  padding-left: unset !important;
}

.btn-cari {
  display: flex;
    justify-content: center;
  max-width: 20%;
  background: #053a69;
  box-shadow: 0 4px 15px rgba(5, 58, 105, 0.25);
  border-radius: 10px;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  margin-bottom: unset !important;
  padding-right: 2% !important;
    padding-left: 2% !important;
}
.msk-cari{
padding: unset !important;
}
.tmbl-cari{

}
@media screen and (min-device-width: 641px) and (min-width: 641px) and (max-device-width: 939px) and (max-width: 939px) and (-webkit-min-device-pixel-ratio: 2) {
.form-custom {
    width: 60%;
}
}
@media screen and (min-device-width: 500px) and (min-width: 500px) and (max-device-width: 640px) and (max-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-custom {
      width: 80%;
    }
}
@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 499px) and (max-width: 499px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-custom {
    width: 100%;
  }
  .float-right{
    display: none;
  }
}
@media screen and (min-device-width: 300px) and (min-width: 300px) and (max-device-width: 410px) and (max-width: 410px) and (-webkit-min-device-pixel-ratio: 2) {
.txt-cari {
    max-width: 65%;
  }

  .btn-cari {
    max-width: 30%;

  }
}
@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 299px) and (max-width: 299px) and (-webkit-min-device-pixel-ratio: 2) {
  .txt-cari {
    max-width: 60%;
  }

  .btn-cari {
    max-width: 35%;

  }
}
@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 758px) and (max-width: 758px) and (-webkit-min-device-pixel-ratio: 2) {
.tabel-css{
  overflow-x: auto;
}
}
</style>
